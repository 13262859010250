import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { nanoid } from 'nanoid';

import { CoachingBookingComponentData } from 'helpers/coachingHelper';
import useGeneral from 'hooks/useGeneral';
import { COACHING_PAYMENT_FIRST_STEP } from 'constants/routes';
import style from '../../../assets/scss/components/CoachingBooking.module.scss';
import { convertKeysToCamelCase } from 'helpers/baseHelper';
import useIsAuthenticated from 'hooks/useIsAuthenticated';
import { REGISTER_TYPE } from 'constants/login';
import useHeader from 'hooks/useHeader';

function CoachingBookingComponent(props) {
  const { coachingId, canBePurchased } = props;
  const { wrapper, title, imageContainer, element, button } = convertKeysToCamelCase(style);
  const { handleRedirect } = useGeneral();
  const { t } = useTranslation();
  const { isUserLoggedIn } = useIsAuthenticated();
  const { handleModalOpen } = useHeader();

  return (
    <div className={wrapper}>
      <div className={element}>
        {CoachingBookingComponentData(props).map((element) => {
          return (
            <Fragment key={nanoid()}>
              <h3 className={`${title} headline3`}>
                <div className={imageContainer}>
                  <img src={element.image} />
                </div>
                {t(`coachings.booking.${element.name}`)}
              </h3>
              <p className="copy">{element.description}</p>
            </Fragment>
          );
        })}

        {isUserLoggedIn && canBePurchased === 1 && (
          <button
            className={`secondary-red ${button}`}
            onClick={() => {
              handleRedirect(COACHING_PAYMENT_FIRST_STEP.replace(':coachId', coachingId));
            }}
          >
            {t('coachings.list.purchase')}
          </button>
        )}
        {!isUserLoggedIn && (
          <button className={`secondary-red ${button}`} onClick={() => handleModalOpen(true, REGISTER_TYPE, true)}>
            {t('general.buttons.register_now')}
          </button>
        )}
      </div>
    </div>
  );
}

export default CoachingBookingComponent;
