import React, { useState } from 'react';
import Slider from 'react-slick';
import { nanoid } from 'nanoid';

import { CAROUSEL_SETTINGS_COACHINGS, CAROUSEL_SETTINGS_COACHINGS_DEFAULT } from '../constants/carousel';
import { convertKeysToCamelCase } from '../helpers/baseHelper';
import style from '../assets/scss/components/VideoCarousel.module.scss';
import TopicCard from './common/cards/TopicCard';

function CoachingCards(props) {
  const { coachings, usedOnDashboard, type = 'coachings' } = props;

  const {
    iconWrapper,
    iconCoachingRight,
    iconCoachingRightCoachingPage,
    iconCoachingLeftCoachingPage,
    iconCoachingLeft,
    sliderWrapperCoachings,
    coachingsWrapper,
  } = convertKeysToCamelCase(style);
  const [slider, setSlider] = useState(null);

  if (!coachings || !coachings.length) {
    return null;
  }
  const hasNavigationButtons = coachings.length > 2;

  return (
    <div
      className={`${coachingsWrapper} ${
        usedOnDashboard ? 'dashboard-carousel-wrapper' : 'coaching-page-carousel-wrapper'
      } ${hasNavigationButtons && 'dashboard-carousel-padding-wrapper'}`}
    >
      {hasNavigationButtons && (
        <span
          className={`${iconWrapper} ${usedOnDashboard ? iconCoachingLeft : iconCoachingLeftCoachingPage}`}
          onClick={slider?.slickPrev}
        >
          <i className="icon icon-right-open-big" />
        </span>
      )}
      {hasNavigationButtons && (
        <span
          className={`${iconWrapper} ${usedOnDashboard ? iconCoachingRight : iconCoachingRightCoachingPage}`}
          onClick={slider?.slickNext}
        >
          <i className="icon icon-right-open-big" />
        </span>
      )}
      <Slider
        {...{ ...(CAROUSEL_SETTINGS_COACHINGS[coachings.length] || CAROUSEL_SETTINGS_COACHINGS_DEFAULT) }}
        ref={(reactSlider) => setSlider(reactSlider)}
        className={sliderWrapperCoachings}
      >
        {coachings.map((coach) => (
          <TopicCard
            id={coach.id}
            image={coach.image}
            isPaidFor={coach.isPaidFor}
            shortDescription={coach.shortDescription}
            title={coach.title}
            price={coach.price ?? null}
            canBePurchased={coach.canBePurchased}
            type={type}
            key={nanoid()}
          />
        ))}
      </Slider>
    </div>
  );
}

export default CoachingCards;
