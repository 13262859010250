import React from 'react';
import { Container, Row, Col, Hidden, Visible } from 'react-grid-system';
import { useTranslation } from 'react-i18next';
import TopicCard from 'components/common/cards/TopicCard';
import TopicModuleCard from '../cards/TopicModuleCard';
import style from 'assets/scss/components/pages/CoachingOffersSection.module.scss';
import { convertKeysToCamelCase } from 'helpers/baseHelper';
import Slider from 'react-slick';
import { CAROUSEL_SETTINGS_COACHINGS_DEFAULT, CAROUSEL_SETTINGS_COACHINGS_MODULES } from 'constants/carousel';

const OffersSection = ({ title, description, topics, type = 'coachings' }) => {
  const { t } = useTranslation();
  const {
    coachingOffersSection,
    coachingOffersSectionText,
    coachingOffersSectionItem,
    coachingOffersSectionSlider,
  } = convertKeysToCamelCase(style);

  return (
    <section className={coachingOffersSection}>
      <Container className="container-fluid">
        <h2 className="headline2">{title}</h2>
        {description && <p className={`${coachingOffersSectionText} highlight`}>{description}</p>}
        {topics && (
          <Row>
            <Hidden xs>
              {topics.map((topic) => (
                <Col xs={12} md={6} key={topic.id} className={coachingOffersSectionItem}>
                  <TopicCard
                    id={topic.id}
                    image={topic.image}
                    isPaidFor={topic.isPaidFor}
                    shortDescription={topic.shortDescription}
                    title={topic.title}
                    price={topic.price ?? null}
                    canBePurchased={topic.canBePurchased}
                    key={topic.id}
                    type={type}
                  />
                </Col>
              ))}
            </Hidden>
            <Visible xs>
              <Col xs={12} className={coachingOffersSectionItem}>
                <Slider
                  {...CAROUSEL_SETTINGS_COACHINGS_DEFAULT}
                  className={`${coachingOffersSectionSlider} coaching-slider`}
                >
                  {topics.map((topic) => (
                    <TopicCard
                      id={topic.id}
                      image={topic.image}
                      isPaidFor={topic.isPaidFor}
                      shortDescription={topic.shortDescription}
                      title={topic.title}
                      price={topic.price ?? null}
                      canBePurchased={topic.canBePurchased}
                      key={topic.id}
                      type={type}
                    />
                  ))}
                </Slider>
              </Col>
            </Visible>
          </Row>
        )}
        {topics &&
          topics.length > 0 &&
          topics
            .filter((topic) => topic.payableModules && topic.payableModules.length)
            .map((topic) => (
              <>
                <h2 className="headline2" style={{ marginTop: 120 }}>
                  {t('coachings.modules.title', { title: topic.title })}
                </h2>
                <Row>
                  <Col xs={12}>
                    <Slider
                      {...CAROUSEL_SETTINGS_COACHINGS_MODULES}
                      slidesToShow={topic.payableModules.length > 1 ? 2 : 1}
                      className={`${coachingOffersSectionSlider} coaching-slider`}
                    >
                      {topic.payableModules.map((module) => {
                        return (
                          <TopicModuleCard
                            key={module.id}
                            title={module.title}
                            image={module.image}
                            price={module.price}
                            topicId={topic.id}
                            type={type}
                            isPaidFor={Boolean(module.isPaidFor)}
                            id={module.id}
                          />
                        );
                      })}
                    </Slider>
                  </Col>
                </Row>
              </>
            ))}
      </Container>
    </section>
  );
};

export default OffersSection;
