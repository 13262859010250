import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { COACHINGS_DETAILS_PAGE } from '../../constants/coaching';
import useDetails from 'hooks/useDetails';
import CoachingBookingComponent from 'components/pages/coachings/CoachingBookingComponent';
import CoachingDropDown from 'components/pages/coachings/CoachingDropDown';
import CoachingCourseContent from 'components/pages/coachings/CoachingCourseContent';
import ContentLayout from 'components/layout/ContentLayout';
import { topicPageByType } from 'helpers/redirectHelper';

import { Container, Row, Col } from 'react-grid-system';
import PresentationOverview from 'components/common/sections/PresentationOverview';
import BackButton from 'components/BackButton';
import useIsAuthenticated from 'hooks/useIsAuthenticated';
import useGeneral from 'hooks/useGeneral';
import { COACHING_PAYMENT_FIRST_STEP } from '../../constants/routes';
import PerksSection from 'components/common/sections/PerksSection';

function CoachingDetailsPage({ type = 'coachings' }) {
  const { t } = useTranslation();
  const { coachId: coachingId, klipId: clipsId } = useParams();
  const { details } = useDetails(coachingId || clipsId, type);
  const { isUserLoggedIn } = useIsAuthenticated();
  const { handleRedirect } = useGeneral();
  const topicId = coachingId || clipsId;

  if (!details) {
    return null;
  }

  const handleBookingRedirect = () => {
    handleRedirect(COACHING_PAYMENT_FIRST_STEP.replace(':coachId', topicId));
  };

  return (
    <ContentLayout title={t('coachings.detail.page.title', { title: details.title })}>
      <PresentationOverview
        topComponent={<BackButton to={topicPageByType(type)} />}
        title={details.title}
        thumbnail={details.image}
        description={details.longDescription}
        buttonClass={'secondary-red'}
        buttonText={isUserLoggedIn ? t('coachings.list.purchase') : t('general.buttons.register_now')}
        onButtonClick={isUserLoggedIn ? handleBookingRedirect : undefined}
        hideButton={details.canBePurchased === 0}
      />

      <Container className="container-fluid">
        <Row>
          <Col xs={12} md={7} lg={8} order={{ xs: 2, md: 1 }}>
            <CoachingCourseContent texts={details.includedIn} />

            <CoachingDropDown
              moduleDetails={details.moduleDetails}
              topicId={topicId}
              type={type}
              canBePurchased={details.canBePurchased}
            />
          </Col>
          <Col xs={12} md={5} lg={4} order={{ xs: 1, md: 2 }}>
            <CoachingBookingComponent
              certificate={details.certificate}
              requirements={details.requirements}
              courseFee={details.courseFee}
              duration={details.duration}
              coachingId={coachingId}
              canBePurchased={details.canBePurchased}
            />
          </Col>
        </Row>
      </Container>

      <PerksSection
        title={t('coachings.details.exclusive.title')}
        items={COACHINGS_DETAILS_PAGE.map((item) => {
          return { title: t(item.titleKey), description: details[item.descriptionKey], image: item.image };
        })}
      />
    </ContentLayout>
  );
}

export default CoachingDetailsPage;
