import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const DEFAULT_TITLE = 'Care&Coach Akademie';

const Head = ({ title, description }) => {
  const checkStaging = () => {
    return window.location.href.includes('staging');
  };

  return (
    <Helmet title={title ? `${title} | ${DEFAULT_TITLE}` : DEFAULT_TITLE}>
      {description && <meta name="description" content={description} />}
      {checkStaging() && <meta name="robots" content="noindex, nofollow" />}
    </Helmet>
  );
};

Head.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

export default Head;
